import React, {useState} from "react";
import { useParams } from "react-router";
import Menu from "./Menu";
import SubMenu from "./SubMenu";
import Header from "./Header";

import "../../css/page/Site.css";

function Site (props) {
    const { page } = props;
    const params = useParams();
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [typeSubMenu, setTypeSubMenu] = useState("");

    const handleSetTypeSubMenu = type => {
        setTypeSubMenu(type);
        setShowSubMenu(!showSubMenu);
    }

    const handleShowSubMenu = () => {
        setShowSubMenu(true);
    }

    const handleHideSubMenu = () => {
        setShowSubMenu(false);
    }

    const goContent = () => {
        switch (page) {
            case "dashboard": return <div><p>Salut mec !</p></div>
            default: return <p>{ page + " non disponible" }</p>
        }
    }

    return (
        <div className="site">
            <Menu page={ page } handleHideSubMenu={ handleHideSubMenu } handleSetTypeSubMenu={ handleSetTypeSubMenu } />
            <SubMenu page={ page } showSubMenu={ showSubMenu } typeSubMenu={ typeSubMenu } handleShowSubMenu={ handleShowSubMenu } handleHideSubMenu={ handleHideSubMenu } />
            <Header page={ page } />
            {
                goContent()
            }
        </div>
    );
}

export default Site;
