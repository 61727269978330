import React from "react";

function SettingIcon (props) {
    const { fill } = props;

    return (
        <svg viewBox="0 0 55.634 55.634" width="1em" height="1em" fill={ fill }>
            <path d="M36.583 37.243c.129-1.356.09-2.707-.106-4.026l3.049-1.359a20.14 20.14 0 00-1.369-4.344 20.251 20.251 0 00-2.312-3.923l-3.051 1.358a16.707 16.707 0 00-2.921-2.771l1.196-3.116a20.21 20.21 0 00-8.448-3.246l-1.197 3.119a16.652 16.652 0 00-4.026.106l-1.351-3.034a20.158 20.158 0 00-8.259 3.702l1.342 3.014a16.68 16.68 0 00-2.773 2.921l-3.108-1.192a19.814 19.814 0 00-2.113 4.037A20.397 20.397 0 000 32.896l3.116 1.196a16.652 16.652 0 00.106 4.026L.173 39.476a20.123 20.123 0 001.369 4.344 20.226 20.226 0 002.313 3.923l3.049-1.358a16.647 16.647 0 002.922 2.772L8.63 52.275a20.158 20.158 0 008.448 3.246l1.197-3.119c1.356.128 2.707.089 4.026-.107l1.351 3.033a20.156 20.156 0 008.26-3.702l-1.343-3.014a16.681 16.681 0 002.773-2.921l3.106 1.192a19.832 19.832 0 002.113-4.036c.553-1.44.928-2.919 1.139-4.409l-3.117-1.195zM28.3 38.91a9.052 9.052 0 01-16.901-6.484A9.051 9.051 0 0128.3 38.91zM53.465 12.395c0-.604-.062-1.195-.168-1.769l2.337-1.35a12.266 12.266 0 00-3.227-5.601l-2.343 1.353a9.629 9.629 0 00-3.063-1.766V.559a12.195 12.195 0 00-3.232-.445c-1.119 0-2.199.164-3.232.445v2.704a9.659 9.659 0 00-3.063 1.766L35.13 3.676a12.293 12.293 0 00-3.227 5.601l2.338 1.35c-.106.575-.169 1.164-.169 1.769s.063 1.195.169 1.769l-2.338 1.35a12.288 12.288 0 003.227 5.601l2.343-1.353a9.64 9.64 0 003.063 1.766v2.704c1.033.282 2.113.445 3.232.445s2.201-.164 3.232-.445v-2.704a9.648 9.648 0 003.063-1.766l2.343 1.353a12.266 12.266 0 003.227-5.601l-2.337-1.35a9.664 9.664 0 00.169-1.77zm-9.695 4.526a4.526 4.526 0 11-.002-9.052 4.526 4.526 0 01.002 9.052z" />
        </svg>
    );
}

export default SettingIcon;
