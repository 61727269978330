class Entity {
    id = 0;
    uuid = "";
    created_at = "";
    updated_at = "";
    deleted_at = "";

    constructor(datas) {
        if(this.constructor === Entity)
            throw new TypeError('Abstract class "Entity" cannot be instantiated directly');

        this.id = datas.id !== undefined ? datas.id : 0;
        this.uuid = datas.uuid !== undefined ? datas.uuid : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.deleted_at = datas.deleted_at !== undefined ? datas.deleted_at : "";
    }
}

export default Entity;
