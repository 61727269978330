import Rest from "../../../class/tool/Rest";
import Partnership from "../Partnerships/Partnership";
import User from "../Users/User";
import cookie from "react-cookies";

class AuthController {
    #_rest = new Rest();
    _callback = null;

    authenticate(email, password) {
        this.#_rest.sendQuery(
            'POST',
            '/login',
            {
                email: email,
                password: password
            },
            this._callback);
    }

    refresh() {
        this.#_rest.sendQuery(
            'POST',
            '/refresh',
            {},
            this._callback);
    }

    me() {
        this.#_rest.sendQuery(
            'GET',
            '/me',
            {},
            this._callback);
    }

    own() {
        this.#_rest.sendQuery(
            'GET',
            '/own',
            {},
            this.handleOwn);
    }
    handleOwn = (response, error) => {
        let listObjects = [];

        if(response.data !== undefined) {
            const data = response.data;

            for(var item in data)
                listObjects.push( new Partnership(data[item]) );
        }

        this._callback(listObjects, error);
    }

    logout() {
        this.#_rest.sendQuery(
            'POST',
            '/logout',
            {},
            this._callback);
    }

    findMyPassword(email) {
        this.#_rest.sendQuery(
            'POST',
            '/findMyPassword',
            {
                email: email
            },
            this._callback);
    }

    // Rules

    hasRules (expected) {
        var cookieDatas = cookie.load('session');
        if (cookieDatas === undefined) return;

        let user = new User(cookieDatas.user);

        for (var i in user.rules)
            if(expected.indexOf(user.rules[i].name.toLowerCase()) >= 0)
                return true;

        return false;
    }
}

export default AuthController;
