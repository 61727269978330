import React from "react";
import DashboardIcon from "../../icons/DashboardIcon";
import SettingIcon from "../../icons/SettingIcon";
import ProjectIcon from "../../icons/ProjectIcon";
import "../../../css/page/menu/Item.css";

function Item (props) {
    const { type, handleSetTypeSubMenu, handleHideSubMenu, selected } = props;

    const selectSVG = type => {
        const isDark = window.matchMedia('(prefers-color-scheme: dark)');
        const color = isDark.matches ? "#FFFFFF" : "#1D1D1D";

        switch (type) {
            case "dashboard": return <DashboardIcon fill={ color } />;
            case "project": return <ProjectIcon fill={ color } />;
            case "setting": return <SettingIcon fill={ color } />;
            default: break;
        }
    }

    const link = () => {
        switch (type) {
            case "dashboard": return "/dashboard";
            case "project": return "/project";
            case "setting": return "/setting/information";
            default: return "";
        }
    }

    const buildRender = () => {
        return <a href={ link() }>
            <div className={ "item " + (selected === "true" ? "selected" : "") + (type === "project" ? " project" : "") } onMouseOver={ setTypeSubMenu } onMouseOut={ hideSubMenu } onClick={ hideSubMenu }>
                {
                    selectSVG(type)
                }
            </div>
        </a>
    }

    const setTypeSubMenu = () => {
        if (handleSetTypeSubMenu)
            handleSetTypeSubMenu(type);
    }
    const hideSubMenu = () => {
        if (handleHideSubMenu)
            handleHideSubMenu();
    }

    return (
        buildRender()
    );
}

export default Item;
