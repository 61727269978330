import React from 'react';
import Main from "./Main";

import './css/App.css';

function App() {
    return (
        <Main />
    );
}

export default App;
