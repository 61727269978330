import React from "react";

export default React.createContext({
    fake: false,
    handleLogout: () => {},
    loggedIn: "",
    updateLoggedIn: state => {},
    user: {},
    updateUser: user => {}
})
