import Entity from "../../../class/abstract/Entity";
import PartnershipType from "../PartnershipTypes/PartnershipType";
import SettingsPartnership from "../SettingsPartnerships/SettingsPartnership";

class Partnership extends Entity {
    company = "";
    phone = "";
    address = "";
    additional = "";
    postalCode = "";
    city = "";
    country = "";
    logo = "";
    partnership_type = null;
    partnership_type_name = "";
    nb_users = 0;
    setting = {};

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.company = datas.company !== undefined ? datas.company : "";
        this.phone = datas.phone !== undefined ? datas.phone : "";
        this.address = datas.address !== undefined ? datas.address : "";
        this.additional = datas.additional !== undefined ? datas.additional : "";
        this.postalCode = datas.postalCode !== undefined ? datas.postalCode : "";
        this.city = datas.city !== undefined ? datas.city : "";
        this.country = datas.country !== undefined ? datas.country : "";
        this.updated_at = datas.updated_at !== undefined ? datas.updated_at : "";
        this.created_at = datas.created_at !== undefined ? datas.created_at : "";
        this.partnership_type_name = datas.partnership_type_name !== undefined ? datas.partnership_type_name : "";
        this.nb_users = datas.nb_users !== undefined ? datas.nb_users : 0;
        this.partnership_type = datas.partnership_type !== undefined ? new PartnershipType(datas.partnership_type) : new PartnershipType();
        this.setting = datas.setting !== undefined ? new SettingsPartnership(datas.setting) : new SettingsPartnership();
    }
}

export default Partnership;
