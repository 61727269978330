import React from "react";

function SettingIcon (props) {
    const { fill } = props;

    return (
        <svg height="1em" viewBox="0 0 609.637 609.637" width="1em" fill={fill}>
            <path d="M363.419 204.933l-4.169 37.046a7.524 7.524 0 002.177 6.14 7.259 7.259 0 006.227 2.177l37.122-4.224c.74-.087 1.383-.435 2.09-.588l-42.881-42.881a20.487 20.487 0 00-.566 2.33zM377.14 185.065L495.742 66.46l47.618 47.619-118.603 118.603zM511.078 50.933L527.21 34.8l47.595 47.595-16.131 16.132zM606.225 51.013a11.146 11.146 0 003.407-8.001 11.419 11.419 0 00-3.407-8.154l-31.429-31.44A11.397 11.397 0 00566.664 0a11.166 11.166 0 00-7.958 3.375l-16.024 15.992 47.594 47.594zM65.322 457.22h21.772c6.012 0 10.886-4.874 10.886-10.886V10.886C97.981 4.874 93.107 0 87.094 0H65.322C29.263.036.041 29.258.005 65.317v421.448a86.92 86.92 0 0165.317-29.545z" />
            <path d="M68.479 609.627h530.267c6.012 0 10.886-4.874 10.886-10.886V163.293c0-6.012-4.874-10.886-10.886-10.886h-63.14L432.252 255.826a42.628 42.628 0 01-25.038 11.92c-36.741 4.126-37.078 4.409-40.257 4.409H206.843v119.748h261.269v-54.431c0-6.012 4.874-10.886 10.886-10.886s10.886 4.874 10.886 10.886v163.293c0 12.025-9.748 21.772-21.772 21.772H250.387c-6.012 0-10.886-4.874-10.886-10.886s4.874-10.886 10.886-10.886h76.203v-87.09H206.843v152.407c0 6.012-4.874 10.886-10.886 10.886s-10.886-4.874-10.886-10.886V272.155h-32.659c-6.012 0-10.886-4.874-10.886-10.886s4.874-10.886 10.886-10.886h186.252a28.988 28.988 0 01-1.012-10.756l4.126-37.089a42.046 42.046 0 0112.345-25.408l24.723-24.723H119.753v293.927c0 18.037-14.622 32.659-32.659 32.659H65.322C29.263 479.029.041 508.251.005 544.31v2.395c2.43 35.861 32.536 63.526 68.474 62.922z" />
            <path d="M348.363 413.676h119.748v87.09H348.363z" />
        </svg>
    );
}

export default SettingIcon;
