import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import LoaderCircle from "../../loader/LoaderCircle";

import '../../../css/page/Options.css';

import SessionContext from "../../../context/SessionContext";

const OptionUser = props => {
    const { show, handleClose } = props;
    const [ inLogout ] = useState(false);
    const { handleLogout } = useContext(SessionContext);

    return (
        <div className={"overOptions userOptions " + (show ? " show" : "")}>
            <nav className="options">
                <Link to={ "/setting/information" }>
                    <p className="item" onClick={ handleClose }>
                        Modifier mes informations
                    </p>
                </Link>
                <p className="item red" onClick={handleLogout}>
                    Déconnexion
                    <LoaderCircle display="loader submitLogout" hide={!inLogout ? "hide" : ""} strokeWidth="8" />
                </p>
            </nav>
        </div>
    );
}

export default OptionUser;
