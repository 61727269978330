import React from "react";

function Search2Icon (props) {
    const { fill, classname, handleClick } = props;

    return (
        <svg width="1em" height="1em" viewBox="0 0 590.556 590.556" fill={ fill } className={ classname } onClick={ handleClick }>
            <path d="M574.099 469.235L437.61 353.434c-3.248-2.709-7.227-4.171-11.266-4.171-5.643 0-11.018 2.843-14.373 7.606a234.584 234.584 0 01-56.611 56.635c-4.1 2.895-6.816 7.319-7.449 12.143-.635 4.828.775 9.602 3.975 13.439l115.873 136.61c7.852 9.422 19.252 14.859 31.23 14.859 10.768 0 21.021-4.324 28.877-12.179l48.98-48.981c8.346-8.348 12.656-19.241 12.137-30.68-.523-11.446-5.808-21.915-14.884-29.48zM218.399 433.743c57.923 0 112.385-22.559 153.348-63.522 40.965-40.965 63.523-95.423 63.523-153.349C435.271 97.29 337.983 0 218.399 0 98.814 0 1.53 97.29 1.53 216.872s97.287 216.871 216.869 216.871zM71.81 216.872c0-80.83 65.759-146.589 146.589-146.589 80.831 0 146.588 65.759 146.588 146.589 0 39.091-15.262 75.885-42.982 103.602-27.721 27.718-64.515 42.984-103.606 42.984-80.83.003-146.589-65.757-146.589-146.586z" />
        </svg>
    );
}

export default Search2Icon;
