import React from "react";
import SubMenuNavItem from "./menu/SubMenuNavItem";
import AuthController from "../../stories/_auth/Auth/AuthController";

import "../../css/page/SubMenu.css";

function SubMenu (props) {
    const { page, showSubMenu, typeSubMenu, handleShowSubMenu, handleHideSubMenu } = props;
    let auth = new AuthController();

    const titleSubMenu = () => {
        switch (typeSubMenu) {
            case "project": return "Projet";
            case "setting": return "Administration";
            default: return "";
        }
    }
    const navListSubItems = () => {
        switch (typeSubMenu) {
            case "project":
                return <nav>
                    <SubMenuNavItem title="Solutions" link="/solution" selected={ page.includes("solution") ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                    <SubMenuNavItem title="Projects" link="/project" selected={ page.includes("project") ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                </nav>
            case "setting":
                return <nav>
                    <SubMenuNavItem title="Mes informations" link="/setting/information" selected={ page.includes("setting") ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                    {
                        auth.hasRules(['boss'])
                        && <div className="clearing">
                            <SubMenuNavItem title="Plateformes" link="/platform" selected={ page.includes("platform") ? "true" : "false" } handleHideSubMenu={ handleHideSubMenu } />
                        </div>
                    }
                </nav>
            default: return <nav />;
        }
    }

    return (
        <div className={ "subMenu " + (showSubMenu ? "show" : "") } onMouseOver={ () => { handleShowSubMenu() } } onMouseOut={ () => { handleHideSubMenu() } }>
            <p className="title">{ titleSubMenu() }</p>
            {
                navListSubItems()
            }
        </div>
    );
}

export default SubMenu;
