import React, {useState} from "react";
import User from "./menu/User";
import Item from "./menu/Item";
import OptionUser from "./menu/OptionUser";
import AuthController from "../../stories/_auth/Auth/AuthController";
import "../../css/page/Menu.css";

function Menu (props) {
    const { page, handleHideSubMenu, handleSetTypeSubMenu } = props;
    const [showOptionUser, setShowOptionUser] = useState(false);
    const auth = new AuthController();

    const handleShowOptionUser = () => {
        setShowOptionUser(!showOptionUser);
    }

    const itemTypeIsSelected = () => {
        if(page.includes("dashboard"))
            return "dashboard";
        else if(page.includes('solution') || page.includes('project'))
            return "project";
        else if(page.includes("partnership") || page.includes('setting') || page.includes('setup') || page.includes('category') || page.includes('brand') || page.includes('provider'))
            return "setting";
        else
            return "";
    }

    return (
        <div className="menu">
            <User onShowOptionUser={handleShowOptionUser} />
            <OptionUser show={ showOptionUser } handleClose={ handleShowOptionUser } />
            <Item type="dashboard" selected={ itemTypeIsSelected() === "dashboard" ? "true" : "false" } />
            <Item type="project" selected={ itemTypeIsSelected() === "project" ? "true" : "false" } handleSetTypeSubMenu={ handleSetTypeSubMenu } handleHideSubMenu={ handleHideSubMenu } />
            <Item type="setting" selected={ itemTypeIsSelected() === "setting" ? "true" : "false" } handleSetTypeSubMenu={ handleSetTypeSubMenu } handleHideSubMenu={ handleHideSubMenu } />
            {
                auth.hasRules(['root', 'developer'])
                && <Item type="project" selected={ itemTypeIsSelected() === "project" ? "true" : "false" } />
            }
        </div>
    );
}

export default Menu;
