import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import cookie from 'react-cookies'
import Init from "./component/initialisation/Init";
import Site from "./component/page/Site";
import AuthController from "./stories/_auth/Auth/AuthController";

import "./css/Theme.css";

import SessionContext from "./context/SessionContext";

function Main () {
    const session = cookie.load('session') !== undefined ? cookie.load('session') : false;
    const [loggedIn, setLoggedIn] = useState(session ? session.logged : "NOT_LOGGED_IN");
    const [user, setUser] = useState(session ? session.user : {});

    const loginURL = process.env.REACT_APP_LOGIN_URL + "/fromproject";

    const removeSession = () => {
        cookie.remove('session');
        cookie.remove('target_url');

        setLoggedIn('NOT_LOGGED_IN');
        setUser({});
    }

    const me = () => {
        const auth = new AuthController();
        auth._callback = handleMe;
        auth.me();
    }
    const handleMe = (response, error) => {
        let session = cookie.load('session');

        if(error) {
            if (!session.remember_me)
                removeSession();
            else
                refresh();

            return;
        }

        session.user = response.data;
        session.logged = 'LOGGED_IN';
        cookie.save('session', session, { domain: process.env.REACT_APP_HOST_ENVIRONMENT });

        setLoggedIn('LOGGED_IN');
        setUser(response.data);
    }

    const logout = () => {
        const auth = new AuthController();
        auth._callback = handleLogout;
        auth.logout();
    }
    const handleLogout = (response, error) => {
        removeSession();
    }

    const refresh = () => {
        const auth = new AuthController();
        auth._callback = handleRefresh;
        auth.refresh();
    }
    const handleRefresh = (response, error) => {
        if(error)
            removeSession();

        setLoggedIn('LOGGED_IN');

        let session = cookie.load('session');
        session.token = response.data.token;
        session.expires_in = Math.floor((Date.now() + (response.data.expires_in * 1000)) / 1000);
        cookie.save('session', session, { domain: process.env.REACT_APP_HOST_ENVIRONMENT });
    }

    const valueSessionContext = {
        handleLogout: logout,
        loggedIn,
        updateLoggedIn: setLoggedIn,
        user,
        updateUser: setUser
    };

    useEffect(() => {
        if(loggedIn === "LOG_WAIT")
            me();
        else if(loggedIn === "LOG_OUT")
            logout();
        else if(loggedIn === "REFRESH")
            refresh();
    });

    return (
        <SessionContext.Provider value={valueSessionContext}>
            <Router>
                {
                    ['NOT_LOGGED_IN', 'LOG_OUT'].indexOf(loggedIn) >= 0
                        ? <Redirect to="/login" />
                        : (
                            ['LOG_WAIT', 'REFRESH'].indexOf(loggedIn) >= 0
                                ? <Redirect to="/init" />
                                : (
                                    (window.location.pathname !== "" && window.location.pathname !== "/" && window.location.pathname !== "/init")
                                    ? <Redirect to={ window.location.pathname + window.location.search } />
                                    : <Redirect to="/dashboard" />
                                )
                        )
                }
                <Switch>
                    <Route exact path="/login" component={() => {
                        window.location.href = loginURL;
                        return null;
                    }}/>
                    <Route exact path="/logout">
                        <Init logout={true} />
                    </Route>
                    <Route exact path="/init">
                        <Init />
                    </Route>
                    <Route exact path="/dashboard">
                        <Site page="dashboard" />
                    </Route>
                    <Route>
                        <p>Outch.. Pas trouvée !</p>
                    </Route>
                </Switch>
            </Router>
        </SessionContext.Provider>
    );
}

export default Main;
