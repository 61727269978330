import Entity from "../../../class/abstract/Entity";

class SettingsPartnership extends Entity {
    logo = "";

    constructor(datas = {}) {
        super(datas);

        this.setAttributes(datas);
    }

    setAttributes(datas) {
        this.logo = datas.logo !== undefined ? datas.logo : "";
    }
}

export default SettingsPartnership;
