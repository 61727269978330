import React from "react";
import {Link} from "react-router-dom";

import "../../../css/page/menu/SubMenuNavItem.css";

function SubMenuNavItem (props) {
    return (
        <Link to={ props.link }><p className={ "subMenuNavItem " + (props.selected === "true" ? "selected" : "") } onClick={ props.handleHideSubMenu }>{ props.title }</p></Link>
    );
}

export default SubMenuNavItem;
