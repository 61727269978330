import React, {useContext} from "react";

import SessionContext from "../../../context/SessionContext";

import "../../../css/page/menu/User.css";

import picUser from "../../../image/ressource/bee.svg";

function User (props) {
    const { onShowOptionUser } = props;
    const { user } = useContext(SessionContext);

    return (
        <div className="user" onClick={ onShowOptionUser }>
            <img src={ picUser } alt="user" />
        </div>
    );
}

export default User;
